/* ROBOTO FONT */

/* THIN 100 */
@font-face {
  font-family: "Roboto";
  src: url("./Roboto-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("./Roboto-ThinItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
}

/* LIGHT 300 */
@font-face {
  font-family: "Roboto";
  src: url("./Roboto-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("./Roboto-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

/* REGULAR 400 */
@font-face {
  font-family: "Roboto";
  src: url("./Roboto-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("./Roboto-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

/* MEDIUM 500 */
@font-face {
  font-family: "Roboto";
  src: url("./Roboto-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("./Roboto-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

/* BOLD 700 */
@font-face {
  font-family: "Roboto";
  src: url("./Roboto-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("./Roboto-BlackItalic.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
}

/* BLACK 900 */
@font-face {
  font-family: "Roboto";
  src: url("./Roboto-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("./Roboto-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}
