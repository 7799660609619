/* Importing fonts */
@import './../fonts/Roboto/main.css';

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

/* :root {
} */

/* all styles */

/* * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}



table {
  width: 100%;
}

a:link,
a:visited,
a:hover,
a:active {
  color: black;
  text-decoration: none;
}


.non-selectable {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-user-select: none;
} */
